import { Locale, LocaleCode } from '../types/locales'

const locales: Array<Locale> = [
  {
    code: LocaleCode.SL,
    file: 'sl.json',
    iso: 'sl-SI',
    name: 'Slovenščina'
  },
  {
    code: LocaleCode.EN,
    file: 'en.json',
    iso: 'en-US',
    name: 'English'
  }
]

export default locales
